import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollCoreFarmData, useFetchProfile, usePollBlockNumber } from 'state/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/PageLoader'
import EasterEgg from './components/EasterEgg'
// import Pools from './views/Pools'
import history from './routerHistory'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
// const Home = lazy(() => import('./views/Home'))
// const Farms = lazy(() => import('./views/Farms'))
// const Lottery = lazy(() => import('./views/Lottery'))
// const Ifos = lazy(() => import('./views/Ifos'))
const NotFound = lazy(() => import('./views/NotFound'))
// const Collectibles = lazy(() => import('./views/Collectibles'))
// const Teams = lazy(() => import('./views/Teams'))
// const Team = lazy(() => import('./views/Teams/Team'))
// const Profile = lazy(() => import('./views/Profile'))
// const TradingCompetition = lazy(() => import('./views/TradingCompetition'))
// const Predictions = lazy(() => import('./views/Predictions'))
// const Voting = lazy(() => import('./views/Voting'))
// const Proposal = lazy(() => import('./views/Voting/Proposal'))
// const CreateProposal = lazy(() => import('./views/Voting/CreateProposal'))
const LotteryNew = lazy(() => import('./views/LotteryNew'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  usePollCoreFarmData()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <LotteryNew />
            </Route>

            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

// <Route path="/home" exact>
//   <Home />
// </Route>
// <Route path="/farms">
//   <Farms />
// </Route>
// <Route path="/pools">
//   <Pools />
// </Route>
// <Route path="/lottery">
//   <Lottery />
// </Route>
// <Route path="/ifo">
//   <Ifos />
// </Route>
// <Route path="/collectibles">
//   <Collectibles />
// </Route>
// <Route exact path="/teams">
//   <Teams />
// </Route>
// <Route path="/teams/:id">
//   <Team />
// </Route>
// <Route path="/profile">
//   <Profile />
// </Route>
// <Route path="/competition">
//   <TradingCompetition />
// </Route>
// <Route path="/prediction">
//   <Predictions />
// </Route>
// <Route exact path="/voting">
//   <Voting />
// </Route>
// <Route exact path="/voting/proposal/create">
//   <CreateProposal />
// </Route>
// <Route path="/voting/proposal/:id">
//   <Proposal />
// </Route>
// {/* Redirect */}
// <Route path="/staking">
//   <Redirect to="/pools" />
// </Route>
// <Route path="/syrup">
//   <Redirect to="/pools" />
// </Route>
// <Route path="/nft">
//   <Redirect to="/collectibles" />
// </Route>

export default React.memo(App)
