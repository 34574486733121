import React from 'react'
import styled from 'styled-components'
import Flex from '../../components/Box/Flex'
import Logo from './components/Logo'
import UserBlock from './components/UserBlock'
import { NavProps } from './types'
import Avatar from './components/Avatar'
import { MENU_HEIGHT, MENU_ENTRY_HEIGHT } from './config'
import ThemeSwitcher from './components/ThemeSwitcher'
import LangSelector from './components/LangSelector'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const SettingsEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 8px;
`

const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  transition: top 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 16px;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme.nav.background};
  border-bottom: solid 2px rgba(133, 133, 133, 0.1);
  z-index: 20;
  transform: translate3d(0, 0, 0);
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
`

const Inner = styled.div`
  flex-grow: 1;
  margin-top: ${MENU_HEIGHT}px;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`

const Menu: React.FC<NavProps> = ({
  account,
  login,
  logout,
  isDark,
  toggleTheme,
  langs,
  setLang,
  currentLang,
  links,
  profile,
  children,
}) => {
  const homeLink = links.find((link) => link.label === 'Home')

  return (
    <Wrapper>
      <StyledNav>
        <Logo href={homeLink?.href ?? '/'} />
        <SettingsEntry>
          <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />
          <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} />
        </SettingsEntry>
        {!!login && !!logout && (
          <Flex>
            <UserBlock account={account} login={login} logout={logout} />
            {profile && <Avatar profile={profile} />}
          </Flex>
        )}
      </StyledNav>
      <BodyWrapper>
        <Inner>{children}</Inner>
      </BodyWrapper>
    </Wrapper>
  )
}

export default Menu
