import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="37" height="37" viewBox="0 0 37 37" fill="none" {...props}>
      <circle cx="18.5" cy="18.5" r="18" stroke="url(#paint0_linear)" />
      <circle cx="17" cy="20" r="16.5" stroke="url(#paint1_linear)" />
      <circle cx="15.5" cy="21.5" r="15" stroke="url(#paint2_linear)" />
      <circle cx="14" cy="23" r="13.5" stroke="url(#paint3_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="32.511"
          y1="-1.25379e-05"
          x2="3.77975"
          y2="0.888966"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#835AF6" />
          <stop offset="1" stopColor="#4578D9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="29.875"
          y1="2.99999"
          x2="3.47328"
          y2="3.81689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#835AF6" />
          <stop offset="1" stopColor="#4578D9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="27.239"
          y1="5.99999"
          x2="3.16681"
          y2="6.74481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#835AF6" />
          <stop offset="1" stopColor="#4578D9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="24.6029"
          y1="8.99999"
          x2="2.86035"
          y2="9.67273"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#835AF6" />
          <stop offset="1" stopColor="#4578D9" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
